// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-objednavka-2-js": () => import("./../src/pages/objednavka-2.js" /* webpackChunkName: "component---src-pages-objednavka-2-js" */),
  "component---src-pages-objednavka-3-js": () => import("./../src/pages/objednavka-3.js" /* webpackChunkName: "component---src-pages-objednavka-3-js" */),
  "component---src-pages-objednavka-4-js": () => import("./../src/pages/objednavka-4.js" /* webpackChunkName: "component---src-pages-objednavka-4-js" */),
  "component---src-pages-objednavka-hotovo-js": () => import("./../src/pages/objednavka-hotovo.js" /* webpackChunkName: "component---src-pages-objednavka-hotovo-js" */),
  "component---src-pages-objednavka-js": () => import("./../src/pages/objednavka.js" /* webpackChunkName: "component---src-pages-objednavka-js" */)
}

